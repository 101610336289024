import styled from 'styled-components'
import { PageTitle, PageTitle2, PageTitle3, PageParagraph } from '../../components/PageTitle';

export const FeatureGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const FeatureTile = styled.div`
  width: 33.333%;
  margin: 0;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 32px;
  transition: 0.3s all;
  cursor: pointer;
  @media (max-width: 50em) {
    width: 100%;
  }

  :hover {
    transition: 0.3s all;
    transform: scale(1.05);
  }

  ${PageTitle3}{
    margin: 8px;
  }

  ${PageParagraph}{
    font-size: 14pt;
  }
`

export const FeatureIcon = styled.img`
  
`;
